import {
  ChangeDetectionStrategy, Component, Input, OnInit,
} from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'pxc-lottie-label',
  templateUrl: './lottie-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LottieLabelComponent implements OnInit {

  @Input() animation: string;
  @Input() message: string;

  public lottieConfig: AnimationOptions;

  ngOnInit(): void {
    this.lottieConfig = {
      path: `assets/animations/${this.animation}.json`,
    };
  }

}
