import {
  Directive, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';
import { filter, throttleTime } from 'rxjs/operators';

const TOP_PADDING = 2;
@UntilDestroy({ checkProperties: true })
@Directive({
  selector: '[scroll-tracker]',
})
export class ScrollTrackerDirective implements OnInit {

  @Input() threshold: { top: number; bottom: number } = { top: 0, bottom: 0 };
  @Input() topGuard = false;
  @Input() bottomGuard = false;
  @Input() isDisabled = false;
  @Output() topThreshold = new EventEmitter();
  @Output() bottomThreshold = new EventEmitter();
  @HostBinding('style.padding-top') private topPaddingStyle = `${TOP_PADDING}px`;

  private lastScrollPosition = 0;

  constructor(
    private element: ElementRef,
  ) { }

  ngOnInit(): void {

    fromEvent<Event>(this.element.nativeElement, 'scroll')
      .pipe(
        filter(() => !this.isDisabled),
        throttleTime(350, undefined, { leading: false, trailing: true }),
        untilDestroyed(this),
      )
      .subscribe((event: any) => {

        const { target } = event;
        const limit = target.scrollHeight - target.clientHeight;

        if (this.threshold.top && target.scrollTop === 0) {
          // If the scroll is at the very top, it gets bound
          // And new elements are prepended without updating scroll position.
          this.element.nativeElement.scrollTo(0, TOP_PADDING);
        }

        if (
          Math.abs(target.scrollTop) >= limit - this.threshold.bottom
          && this.lastScrollPosition <= Math.abs(target.scrollTop)
          && this.lastScrollPosition !== Math.abs(target.scrollTop)
          && !this.bottomGuard
        ) {
          this.bottomThreshold.emit();
        } else if (
          Math.abs(target.scrollTop) <= this.threshold.top
          && Math.abs(this.lastScrollPosition) >= Math.abs(target.scrollTop)
          && this.lastScrollPosition !== Math.abs(target.scrollTop)
          && !this.topGuard
        ) {
          this.topThreshold.emit();
        }

        this.lastScrollPosition = target.scrollTop;
      });
  }

}
