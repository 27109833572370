<button
  tuiButton
  [appearance]="appearance"
  data-testid="button"
  [iconStart]="icon"
  [tuiDropdown]="dropdownContent"
  tuiDropdownAlign="right"
  [(tuiDropdownOpen)]="open"
>
  <div class="flex gap-3">
    {{ actions[selectedKey]?.title }}
    <tui-icon
      icon="@tui.chevron-down"
      class="transition-all duration-300"
      [ngClass]="{ 'rotate-180': open }"
    ></tui-icon>
  </div>
</button>

<ng-template #dropdownContent>
  <div
    class="flex flex-col p-2"
    data-testid="dropdown"
  >
    @for (actionsKeyValue of actions | keyvalue; track actionsKeyValue) {
      <div class="flex cursor-pointer items-center rounded-xl p-2 transition-colors duration-300 hover:bg-neutral-1">
        @let action = actionsKeyValue.value;
        @if (action) {
          <div
            (click)="onClick(action)"
            class="flex w-full items-center justify-between gap-2"
          >
            <div class="flex items-center gap-3">
              @if (action.icon) {
                <tui-icon
                  [icon]="action.icon"
                  class="text-secondary"
                ></tui-icon>
              }
              <div class="flex flex-col">
                <p class="font-semibold whitespace-normal">
                  {{ action.title }}
                </p>
                @if (action.label) {
                  <p class="whitespace-normal text-secondary">
                    {{ action.label }}
                  </p>
                }
              </div>
            </div>
            @if (actionsKeyValue.key === selectedKey) {
              <tui-icon
                class="text-secondary"
                icon="@tui.check"
              ></tui-icon>
            }
          </div>
        }
      </div>
    }
  </div>
</ng-template>

