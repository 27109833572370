<div
  class="flex flex-col items-center justify-center gap-2"
  [ngClass]="{
    'h-screen w-screen bg-base-alt': fullscreen,
    'h-full': !fullscreen,
  }"
>
  @if (useLogo) {
    <div
      class="logo h-16 w-16 md:h-24 md:w-24"
      [class.animate]="status === Status.LOADING"
      data-testid="svg-container"
    >
      <svg
        viewBox="0 0 214 214"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="bottom-right-corner"
          [ngClass]="dark ? 'fill-text-tertiary opacity-50' : 'fill-pixablue-1'"
          d="M148.402 120.924V148.602H120.896V179.703H167.976C174.453 179.703 179.303 174.481 179.303 168.375V121.095L148.402 120.924Z"
        />
        <path
          class="top-left-corner"
          [ngClass]="dark ? 'fill-text-tertiary opacity-50' : 'fill-pixablue-1'"
          d="M45.7674 34.668C39.2904 34.668 34.4397 39.8896 34.4397 45.9957V93.0757H65.541V65.5696H93.2184V34.4683L45.7674 34.668Z"
        />
        <path
          class="bottom-left-corner"
          [ngClass]="dark ? 'fill-text-tertiary opacity-50' : 'fill-pixablue-1'"
          d="M65.7122 121.095H34.6108V168.175C34.6108 174.653 39.8324 179.503 45.9386 179.503H93.0186V148.602H65.5124L65.7122 121.095Z"
        />
        <g
          class="mire"
          data-testid="mire"
          [class.rotate-45]="status === Status.ERROR"
        >
          <path
            class="mire-bottom-left"
            [ngClass]="dark ? 'fill-text-tertiary opacity-50' : 'fill-pixablue-1'"
            d="M148.345 6.79102H179.446V34.4683H207.123L207.152 65.5696H179.446V93.0758H148.345V65.5696H120.867V34.4683H148.345V6.79102Z"
          />
          <path
            class="mire-top-right"
            [ngClass]="dark ? 'fill-text-tertiary opacity-50' : 'fill-pixared-1'"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M179.446 6.79102H148.345V34.3447L179.57 65.5696H207.152L207.123 34.4683H179.446V6.79102Z"
          />
        </g>
      </svg>
    </div>
  } @else {
    <pxc-spinner
      class="my-5"
      data-testid="loader-spinner"
    ></pxc-spinner>
  }
  <p class="max-w-prose text-balance text-center text-secondary">
    <ng-content></ng-content>
  </p>
</div>
