<tui-elastic-container>
  <div class="flex flex-col items-center justify-center duration-300 ease-out animate-in fade-in">
    <ng-lottie
      [options]="lottieConfig"
      data-testid="animation"
      class="aspect-square w-4/5 max-w-xs"
    ></ng-lottie>
    @if (message) {
      <h5
        tuiTitle="m"
        class="max-w-prose"
      >
        {{ message }}
      </h5>
    }
  </div>
</tui-elastic-container>
