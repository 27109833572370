/* eslint-disable no-bitwise */
import { format } from 'date-fns';
import { combineLatest, first, from, map, Observable, of, switchMap } from 'rxjs';

export const iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;

export const MILLISECONDS_IN_ONE_SECOND = 1000;
export const MILLISECONDS_IN_ONE_MINUTE = MILLISECONDS_IN_ONE_SECOND * 60;
export const MILLISECONDS_IN_ONE_HOUR = MILLISECONDS_IN_ONE_MINUTE * 60;
export const MILLISECONDS_IN_ONE_DAY = MILLISECONDS_IN_ONE_HOUR * 24;

export const uuidv4 = (): string => (
  ((1e7).toString() + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (
    // eslint-disable-next-line no-bitwise
    (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16)),
  )
);

export const formatToIso = (date: Date): string => date.toISOString();

export const formatBirthdate = (date: Date): string => format(date, 'yyyy-MM-dd');

export const getISODate = (date: Date): string =>
  date.toISOString().substring(0, date.toISOString().indexOf('T'));

export const isDateFromFuture = (date: Date): boolean => date.getTime() > new Date().getTime() || date.getTime() === 0;
export const isDateFromPast = (date: Date): boolean => date.getTime() < new Date().getTime() && date.getTime() !== 0;

export const msToTime = (ms: number): string => {
  const seconds = +(ms / 1000).toFixed(1);
  const minutes = +(ms / (1000 * 60)).toFixed(1);
  const hours = +(ms / (1000 * 60 * 60)).toFixed(1);
  const days = +(ms / (1000 * 60 * 60 * 24)).toFixed(1);

  if (seconds < 60) { return `${seconds} secondes`; }
  if (minutes < 60) { return `${minutes} minutes`; }
  if (hours < 24) { return `${hours} heures`; }
  return `${days} jours`;
};

export const getDateBounds = (dates: Date[]): any => dates.reduce((acc, date) => ({
  min: date.getTime() < acc.min.getTime() ? date : acc.min,
  max: date.getTime() > acc.max.getTime() ? date : acc.max,
}), { min: dates[0], max: dates[0] });

export const normalizeString = (value: string): string => value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const match = (value: string, tags: string[]): boolean => {
  const tokens = (value ?? '').toLowerCase().split(' ');
  const chain = normalizeString(tags.map((data) => data?.toLowerCase()).join(' '));
  return tokens.every((token) => (
    chain.includes(normalizeString(token))
  ));
};

export const lens = (entity: unknown, path: string): unknown => (
  path.split('.').reduce((obj, key) => (obj ? obj[key] : undefined), entity)
);

export const replaceAll = (str: string, find: string, replace: string): string => (
  str.replace(new RegExp(find, 'g'), replace));

export const sanitizeFilename = (filename: string): string => replaceAll(filename, ' ', '_');

export const downloadSource = (source: string, filename: string): void => {
  const link = document.createElement('a');
  link.href = source;
  link.download = sanitizeFilename(filename);
  link.click();
  link.remove();
};

export const downloadBlob = (source: Blob, filename: string): void => {
  const objectUrl = URL.createObjectURL(source);
  downloadSource(objectUrl, sanitizeFilename(filename));
  URL.revokeObjectURL(objectUrl);
};

export const fetchBlob = (source: string): Observable<Blob> => from(fetch(source)).pipe(
  first(),
  switchMap((response: Response) => response.blob()),
);

export const downloadFile = (source: string, filename: string): void => {
  fetchBlob(source).subscribe({
    next: (blob: Blob) => {
      downloadBlob(blob, filename);
    },
  });
};

export const base64toBlob = (base64: string): Blob => {

  const byteString = atob(base64.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' });
};

export const select = <T>(elements: T[], predicate: (T) => boolean, count?: number ): T[] => {
  const selectedElements = [];
  elements.forEach((element) => {
    if (count && selectedElements.length >= count) {
      return;
    }
    if (predicate(element)) {
      selectedElements.push(element);
    }
  });
  return selectedElements;
};

export const splitLines = (lines: string[], maxLength = 124, maxLines = null): string[] => {
  const result = [...lines].reduce((acc, line) => {
    if (maxLines && acc.length >= maxLines) {
      return acc;
    } else if (line.length > maxLength) {
      const lastIndex = line.substring(0, maxLength).lastIndexOf(' ');
      if (lastIndex) {
        acc.push(
          line.substring(0, lastIndex),
          ...splitLines([line.substring(lastIndex + 1, line.length - 1)], maxLength, maxLines || (maxLines - 1)),
        );
      } else {
        acc.push(line);
      }
    } else {
      acc.push(line);
    }
    return acc;
  }, []);

  if (result.length < lines.length) {
    result[result.length - 1 ] += '...';
  }

  return result;
};

export const stringToBoolean = (value?: string): boolean => {
  try {
    return JSON.parse(value?.toLowerCase());
  } catch (error) {
    return undefined;
  }
};

export const isEverythingTrue = (...values: (boolean)[]): boolean => values.every((v) => v);

export const isEverythingTrue$ = (...values: (boolean | Observable<boolean>)[]): Observable<boolean> =>
  combineLatest(
    values.map((v) => v instanceof Observable ? v : of(v)),
  ).pipe(
    map((results) => isEverythingTrue(...results)),
  );

export const isIso8601 = (value: string): boolean => {
  if (value === null || value === undefined) {
    return false;
  }
  return iso8601.test(value);
};

export const convertDateFields = (body: unknown): unknown => {
  if (body === null || body === undefined) {
    return body;
  }

  if (typeof body !== 'object') {
    return body;
  }

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIso8601(value)) {
      body[key] = new Date(value);
    } else if (typeof value === 'object') {
      convertDateFields(value);
    }
  }

  return body;
};

export const pluralizeWord = (word: string, amount: number): string => amount > 1 ? `${word}s` : word;

export const pluralize = (word: string, amount: number): string => `${amount} ${pluralizeWord(word, amount)}`;

export const millisecondsToHours = (milliseconds: number): number => milliseconds / MILLISECONDS_IN_ONE_HOUR;

export const millisecondsToDays = (milliseconds: number): number => milliseconds / MILLISECONDS_IN_ONE_DAY;

export const millisecondsSince = (date: Date): number => new Date().getTime() - date.getTime();

export const normalizedStartsWith = (source: string, search: string): boolean =>
  !search ||
  normalizeString(source.toLocaleLowerCase()).startsWith(normalizeString(search.toLocaleLowerCase())) || 
  normalizeString(source.toLocaleUpperCase()).startsWith(normalizeString(search.toLocaleUpperCase()));
